import {routeDict} from '~/router'

let menu = [
    // {
    //     icon: 'fas fa-book', text: 'Blog', regex: /^\/(blog)/,
    //     items: [
    //         {icon: 'fas fa-book-open', text: 'Blog List', route: routeDict['blog-list']},
    //         {icon: 'fas fa-shoe-prints', text: 'Blog Footer List', route: routeDict['blog-footer-list']},
    //         {icon: 'fas fa-star', text: 'Blog Featured', route: routeDict['blog-featured']}
    //     ]
    // }
]

export default menu
